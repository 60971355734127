import { render, staticRenderFns } from "./CaraPembayaran.vue?vue&type=template&id=34bf5209&"
import script from "./CaraPembayaran.vue?vue&type=script&lang=js&"
export * from "./CaraPembayaran.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports